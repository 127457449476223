import { commaSeparated } from "@/store/utils";

/**
 * @param {string} numberString
 * @param {number|string} iterationOffset
 * @returns {object}
 */
export function convertToPalindrome(numberString, iterationOffset = 0) {
  const steps = [];
  let number = BigInt(numberString);
  const iStart = 1n + BigInt(iterationOffset);
  const iEnd = 100n + BigInt(iterationOffset);
  let i;
  for (i = BigInt(iStart); i <= BigInt(iEnd); i++) {
    const numberReversed = getReversedNumber(number);
    const sum = number + numberReversed;
    steps.push({
      iteration: commaSeparated(i),
      calculation: `${commaSeparated(`${number} + ${numberReversed}`)} = ${sum}`,
      tutorial: "Sum the number with its reversed version"
    });
    if (sum === getReversedNumber(sum)) {
      return { steps };
    }
    number = sum;
  }
  return {
    steps,
    lastNumber: number,
    lastIteration: i - 1n,
    isCanContinue: true
  };
}

/**
 * @param {BigInt} number
 * @returns {BigInt}
 */
function getReversedNumber(number) {
  const numberStringReversed = number.toString().split("").reverse().join("");
  return BigInt(numberStringReversed);
}
